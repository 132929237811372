
// {
//   title:'',
//     description:'',
//   image:''
// }

// import projectImages from '../img/projects/*.png';

const upcomingProjects = [


  // {
  //   title:'Untitled project',
  //   description:'Opening late April to early May on Genify.',
  //   image:'',
  //   link: 'https://genify.xyz/',
  // }
]

const selectedProjects = [
  {
    title:'Island Mist',
    date: 'May 30, 2024',
    description: '"Island Mist" presents a tranquil aerial perspective of islands adrift in a calm sea. Soft clouds imbue the scene with tranquility, while the touch of light illuminates the seascape below.',
    link: 'https://btc.genify.xyz/collection/8',
    image: 'project-island-mist'
  },
  {
    title:'Break Free',
    date: 'December 15, 2023',
    description: '"Break Free" is about escaping from barriers and limits. The sweeping arcs and bold strokes contrast with the regular pattern of lines and circles as they spin outward, breaking the borders that seek to confine them.',
    link: 'https://www.fxhash.xyz/generative/0x4D8891697d942F923F20972Dd2f2313BBBf757E2',
    image: 'project-break-free'
  },
  {
    title:'Arboretum',
    date: 'May 24, 2023',
    description: 'Arboretum explores the intersection between art and technology, blending impressionist painting styles with code. Drawing inspiration from the works of great artists like Claude Monet, Paul Cézanne, and Vincent van Gogh, the collection aims to capture nature\'s essence through color, light, and texture.',
    link: 'https://genify.xyz/collection/lambda/49/items',
    image: 'project-arboretum'
  },
  {
    title:'Lake Festival',
    date: 'April 13, 2023',
    description: 'You are on the shore of a lake, waiting for the festival to begin. A soft wind blows, and the remains of a storm are passing away.',
    link: 'https://verse.works/artworks/0ce696c3-d4e9-4745-ad37-c660600f8d86',
    image: 'project-lake-festival'
  },
  {
    title:'Deep Forest',
    description: 'I love being in nature and impressionistic paintings. Deep Forest is my latest attempt to combine them with JavaScript. The editions will range from open, sparse forests to lush, dense, leafy wonderlands. Fall editions may render as Klimpt-style birch forests - my original inspiration for this work.',
    date: 'July 7, 2022',
    link: 'https://www.fxhash.xyz/generative/16047',
    image: 'project-deep-forest'
  },
  {
    title:'Cold Mountain',
    description: 'An impressionistic painting of a maintain on a foggy winter\'s day. The view will be of a mountain, mountain range, valley, or meadow. At nite, the lights come on, and the trees glow in the moonlight.',
    date:'January 14, 2022',
    link: 'https://tender.art/project/cold-mountain',
    image: 'project-cold-mountain'
  },
  {
    title:'Orchard',
    description: 'A whimsical "natural media" rendering of a fruit tree during one of the four seasons.',
    date: 'February 7, 2022',
    link: 'https://www.fxhash.xyz/generative/8828',
    image: 'project-orchard'
  },


  // {
  //   title:'Untitled project',
  //   description:'Opening late April to early May on Genify.',
  //   image:'',
  //   link: 'https://genify.xyz/',
  // }
]

const openProject = (t, p) => (e) =>{
  let link;
  if(t === 0) {
    // console.log(upcomingProjects[p].title);
    link = upcomingProjects[p].link;
  } else if(t===1) {
    // console.log(selectedProjects[p].title);
    link = selectedProjects[p].link;
  }

  if(link) window.open(link)
  return false;
}


const createProjectCard = (projObj, idx) => {
  let str = '';
  // if(projObj.image.length > 1) str += `<img src="${projectImages[projObj.image]}" alt="${projObj.title} preview image">`
  if(projObj.image.length > 1) str += `<img src="${[projObj.image]}.png" alt="${projObj.title} preview image">`
  str += `<section class="desc"><h1>${projObj.title}</h1>`;
  if(projObj.date.length > 1) str += `<p class="project-date">${projObj.date}</p>`
    str += `<p>${projObj.description}</p>`
  if(projObj.link.length > 1) str += `<p><a href="${projObj.link}">View more</a></p>`;
  str += `</section>`
  return `<section class="project" id="project-id-${idx}">${str}</section>`;
}



const onLoaded = () => {
  window.removeEventListener('load', onLoaded)

  // const upcomingEl = document.getElementById('upcoming-projects')
  // upcomingEl.innerHTML = upcomingProjects.reduce((acc,p,i) => {
  //   acc += createProjectCard(p,i)
  //   return acc
  // },'')

  const selectedEl = document.getElementById('selected-projects')
  selectedEl.innerHTML = selectedProjects.reduce((acc,p,i) => {
    acc += createProjectCard(p,i)
    return acc
  },'')

  // Array.from(upcomingEl.getElementsByClassName('project')).forEach((el, idx) => {
  //   el.addEventListener('click',openProject(0,idx))
  // })

  Array.from(selectedEl.getElementsByClassName('project')).forEach((el, idx) => {
    el.addEventListener('click',openProject(1,idx))
  })
}

window.addEventListener('load', onLoaded)
